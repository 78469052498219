export const ROUTES = {
  home: "/",
  maintenance: "/maintenance",
  contact: "/contact",
  employers: "/employers",
  candidates: "/candidates",
  blog: "/blog",
  howToDeleteAccount: "/how-to-delete-account",
  candidatesFeatures: "/candidates/features",
  employersPricing: "/employers/pricing",
  employersFeatures: "/employers/features",
  employersBrowseCandidates: "/employers/browse-candidates",
  confirm: "/confirm",
  register: "/register",
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  invalidLink: "/invalid-link",
  privacyPolicy: "/privacy-policy",
  action: "/action",
  termsAndConditions: "/terms-and-conditions",
  actionReference: "/action-reference",
  createCompanyMember: "/create-member",
  createCompanyOwner: "/create-owner",
  dashboard: "/dashboard",
  dashboardHome: "/dashboard/home",
  dashboardJobs: "/dashboard/jobs",
  dashboardJobsCreate: "/dashboard/jobs/create",
  dashboardJobView: "/dashboard/jobs/view",
  dashboardJobsEdit: "/dashboard/jobs/edit",
  dashboardJobsInvite: "/dashboard/jobs/invite",
  dashboardArchivedJobsRepost: "/dashboard/archived-jobs/repost",
  dashboardArchivedJobView: "/dashboard/archived-jobs/view",
  dashboardApplications: "/dashboard/applications",
  dashboardCandidates: "/dashboard/candidates",
  dashboardCandidateView: "/dashboard/candidates/view",
  dashboardPayments: "/dashboard/payments",
  dashboardChat: "/dashboard/chat",
  dashboardArchivedJobs: "/dashboard/archived-jobs",
  dashboardSettings: "/dashboard/settings",
  dashboardCompanySettings: "/dashboard/settings/company",
  dashboardTeamSettings: "/dashboard/settings/team",
  dashboardBillingSettings: "/dashboard/settings/billing",
  dashboardAccountSettings: "/dashboard/settings/account",
};

export const EXTERNAL_LINKS = {
  linkedIn: "https://www.linkedin.com/company/104971561/",
  twitter: "https://twitter.com",
  facebook: "https://www.facebook.com/profile.php?id=61557181674549",
  instagram: "https://www.instagram.com/hirable_sa/",
  tiktok: "https://www.tiktok.com/@hirable_sa?lang=en",
  // TODO: replace with actual app download link
  playStoreDownload: "/",
  iosDownload: "/",
};
