<script setup lang="ts">
interface Props {
  type?: "button" | "submit" | "reset";
  spinner?: boolean;
  danger?: boolean;
  secondary?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  type: "button",
});

const buttonColour = computed(() => {
  if (props.danger) return "h-btn-danger";
  if (props.secondary) return "h-btn-secondary";
  return "h-btn-primary";
});
</script>

<template>
  <button :type="type" :class="buttonColour" class="h-btn">
    <Icon v-if="spinner" name="svg-spinners:3-dots-move" class="h-6 w-6" />
    <slot v-else />
  </button>
</template>
