<template>
  <NuxtLayout name="fullpage">
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div class="text-center">
        <p class="text-base font-semibold text-primary">404</p>
        <h1 class="mt-4 text-3xl font-bold tracking-tight text-dark-gray sm:text-5xl">Page not found</h1>
        <p class="mt-6 text-base leading-7 text-medium-gray">Sorry, we couldn’t find the page you’re looking for.</p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <NuxtLink :to="ROUTES.home">
            <PrimaryButton>Go back home</PrimaryButton>
          </NuxtLink>
          <NuxtLink :to="ROUTES.contact" class="text-sm font-semibold text-dark-gray"
            >Contact support <span aria-hidden="true">&rarr;</span></NuxtLink
          >
        </div>
      </div>
    </main>
  </NuxtLayout>
</template>
